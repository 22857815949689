<template>
  <div v-if="isLoadingMain">
    <Spinner  classes="mx-auto my-4"></Spinner>
  </div>
  <div v-else class="row justify-content-center mw-100 mx-0">
    <div class="backButtonContainer pt-2">
      <BackButton :scrollToSticky="100" top="0.5rem" />
    </div>
    <card class="card-chart card-chart-pie box-text mt-3 mb-3 mx-3 cursor-pointer border">
      <div class="row" @click="goToStoreService($route.params.slug)">
        <div class="col-9 row">
          <h2 class="col-12 mb-0 font-weight-bold text-capitalize ml-3">
            {{ service.name }}
            <span class="font-weight-light time-size">[{{ service.time }}m]</span>
          </h2>
          <p class="col-12 align-self-start ml-3">
            {{ service.description }}
          </p>
          <h3 class="col-12 align-self-end card-title font-weight-bold ml-3">
            {{ service.price.toFixed(2) }}€
          </h3>
        </div>

        <div class="col-3 pl-1 pr-0">
          <div class="row chart-area p-0 m-0">
            <img 
              v-if="controlServicesPictures(service.pictures)"
              :src="controlServicesPictures(service.pictures)"
              @error="replaceByDefault"
              :alt="$t('alt.service')"
              class="col-12 p-0 img-services"
              width="100%"
              height="100%"
            />
            <img v-else src="@/assets/img/img-default.png" :alt="$t('alt.service')" class="col-12 p-0 img-services" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </card>

    <card v-if="employees.length > 0" class="card-chart card-chart-pie box-text mt-3 mb-3 mx-3 cursor-pointer border">
      <div class="d-flex flex-wrap px-2 justify-content-center">
          <base-button
            @click="changeEmployee('any')"
            :type="employeeSelected === 'any'? 'success':'simple'"
            class="col-12 col-sm-3 col-lg-2 employee-btn p-2 d-flex flex-column align-items-center justify-content-center"
            :disabled="isLoading || employeeSelected === 'any'"
          >
            <div class="btn-text">
              {{ $t("allEmployee") }}
            </div>
          </base-button>
          <base-button
            v-for="(employee, index) in employees"
            :key="index"
            @click="changeEmployee(employee.uuid)"
            :type="employeeSelected === employee.uuid? 'success':'simple'"
            class="employee-btn col-12 col-sm-3 col-lg-2 p-2 d-flex  align-items-center justify-content-between"
            :disabled="isLoading || employeeSelected === employee.uuid"
          >
            <div class="btn-img">
              <img
                v-if="employee.picture"
                :src="employee.picture"
                @error="replaceByDefault"
                :alt="$t('alt.employee')"
                class="img-employee2"
                width="100%"
                height="100%"
              />
              <img v-else src="@/assets/img/img-default.png" :alt="$t('alt.employee')" class="img-employee2" width="100%" height="100%" />
            </div>
            <div class="btn-text">
                {{ employee.name }}
            </div>
          </base-button>
      </div>
    </card>

    <div v-if="employees.length > 0" class="w-100">
      <div class="row mx-3 booking-card">
        <card class="card-chart card-chart-pie px-2 mb-0 box-text mt-3 text-center border col-12">
          <div class="dataPicker-height">
            <h4 class="font-weight-bold mb-3 d-flex justify-content-center">
              {{ $t("bookingMessage.chooseDateTimeService") }}
              <div v-if="isLoadingBookingsAvailable && !isLoading" class="d-flex justify-content-center align-items-center ml-4">
                <Spinner classes="mx-auto"></Spinner>
              </div>
            </h4>
            <DatePicker
              v-show="!isLoading"
              v-model="date"
              :min-date="new Date()"
              :disabled-dates="{ start: new Date(), end: null }"
              :available-dates="availableDays"
              :attributes="attrs"
              :locale="locale"
              @dayclick="onDayClick"
              @update:to-page="toPageMoved"
              color="green"
              is-expanded
            ></DatePicker>
            <div v-if="isLoading" class="dataPicker-height d-flex justify-content-center align-items-center">
              <Spinner v-if="isLoading" classes="mx-auto" style="height:100%"></Spinner>
            </div>

          </div>
          <div class="mt-3">
            <span v-if="bookingsAvailable.length > 0 " class="font-weight-bold text-success" >{{ $t("bookingMessage.firstAvailableAppointmentSelected") }}</span>
            <h4 class="font-weight-bold mt-3">
              {{ longDate}}
            </h4>
          </div>

          <card v-if="!isLoading && bookingsAvailable.length > 0" class="mb-3" bodyClasses="pb-0">
            <tabs-pills type="success" vertical class="row text-left pb-0" rowClass="row" colClasses="col-6 col-sm-4 col-md-3 col-lg-2" tabNavWrapperClasses="col-12" v-model="selectedTime" @change="onChangeTab"
            >
              <tab-pane v-for="(availableSlot, index) in bookingsAvailable" :key="index" :label="(availableSlot.hour).slice(0,-3)"></tab-pane>
            </tabs-pills>
          </card>
          
          <Spinner v-if="isLoading" classes="mx-auto"></Spinner>
          <span v-if="!isLoading && bookingsAvailable.length === 0">
            {{ 
              $tc( isAfterTwoMonths? "bookingMessage.slotsNotAvailable" :
                  employeeSelected === "any" ? "bookingMessage.noHoursAvailable" :
                  "bookingMessage.employeeNoHoursAvailable"
              )
            }}
          </span>
          
          <base-textarea v-show="bookingsAvailable.length > 0" :label="`${$t('bookingInfo.commentToStore')}`" v-model="customer_comment"></base-textarea>

          <div v-if="other_services.length > 0" class="d-flex flex-column py-3">
            <label for="">{{$tc('additionalService', 2)}}</label>
            <div class="d-flex flex-wrap">
              <div class="badge-service" 
              v-for="(extraService, index) in other_services"   
              :key="Math.random(extraService.id)" 
              @click="removeExtraService(index)"
              >
              {{extraService.name}}
              <em class="tim-icons icon-trash-simple icon-remove"></em>
            </div>
          </div>
        </div>
        </card>
      </div>


      <div class="d-block d-sm-flex flex-md-wrap justify-content-around mt-3 mb-3 mx-3">
        <base-button
          class="col-12 col-sm-5 col-md-4 my-2 py-3"
          type="tertiary"
          :disabled="isSavingBooking || !(bookingsAvailable && bookingsAvailable.length > 0)"
          @click="isModalExtraService = true"
        >
          <div class="d-flex align-items-center justify-content-center">
            <em class="tim-icons icon-simple-add mb-1 mr-2"></em>
            <span>{{ $t("addAdditionalService") }}</span>
          </div>
        </base-button>

        <base-button
          class="col-12 col-sm-5 col-md-4 mt-2 mt-md- py-3"
          type="tertiary"
          :disabled="isSavingBooking || !(bookingsAvailable && bookingsAvailable.length > 0)"
          @click="bookingService"
        >
          <div class="d-flex justify-content-center align-items-center mx-sm-2" style="vertical-align: auto;">
            <span>{{ $t("book") }}</span>
            <Spinner v-if="isSavingBooking" classes="mx-2 my-md-0"></Spinner>
          </div>
        </base-button>
      </div>
    </div>

    <div v-else class="col-12 row justify-content-center mt-3">
      <h4 class="text-center text-black-50">{{ $t('serviceNotAvailable') }}</h4>
    </div>

    <div v-if="isModalShow" class="login-modal">
      <Login v-if="isLoginShow" class="max-width-480 mx-auto" :isLoginBooking="true" :modal="true" @saveBooking="bookingService" @changeModalShow="editModalShow" @showLogin="showLogin"></Login>
      <Register v-else class="max-width-480 mx-auto" :isRegisterBooking="true" :modal="true" @saveBooking="bookingService" @changeModalShow="editModalShow" @showLogin="showLogin"></Register>
    </div>

    <modal
      :show="isModalAddCalendar"
      :showClose="false"
      @hide="isModalAddCalendar = false"
      :title="$tc(`confirm.addBookingToYourCalendar`)"
      hide-footer
    >
      <header>
        <div class="row justify-content-center align-items-center">
          <h4 class="my-2 text-black">{{ $tc(`confirm.addBookingToYourCalendar`) }}
          </h4>
        </div>
      </header>
      <hr />
      <div class="d-block d-sm-flex justify-content-between">
        <base-button class="google-calendar-background " @click="addBookingToCalendar(true)">
          {{ $t("addBookingToCalendar") }}
        </base-button>

        <base-button type="danger" @click="addBookingToCalendar(false)">
          {{ $t("noThanks") }}
        </base-button>
      </div>
    </modal>
    <modal 
      :show="isModalExtraService"
      :showClose="true"
      @hide="isModalExtraService = false"
      :title="$tc(`addAdditionalService`)"
      hide-footer
    >
      <header>
        <div class="row justify-content-between align-items-center px-4">
          <h4 class="my-2 text-black">{{ $tc(`addAdditionalService`) }}
          </h4>
          <base-button simple class="px-3 py-2 col-1 d-flex justify-content-center" @click="isModalExtraService = false" data-testid="btn-closeModal">
              <em class="tim-icons icon-simple-remove text-dark"></em>
            </base-button>
        </div>
      </header>
      <div class="row extra-service body p-3">
        <base-button 
          type="primary" 
          simple 
          class="col-12 d-flex button justify-content-between mb-3 " 
          v-for="service in services" 
          :key="service.id"
          @click="addAdditionalService(service)"
        >
          <div class="d-flex align-items-center">
            <h6 class="mb-0 text-black">{{service.name}}</h6>
              <span class="pl-2 text-black-light">{{service.time}}min</span>
              <span class="pl-2 text-black-light">{{service.price}}€</span>
            
          </div>
          <em class="tim-icons icon-simple-add pr-2"></em>
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  BackButton,
  BaseTextarea,
  Card,
  Modal,
  Spinner,
  TabPane,
  TabsPills
} from "@/components/index";
import { DateTime } from "luxon";
import { DatePicker } from "v-calendar";
import Login from "../../shared/Auth/Login";
import Register from '../../shared/Auth/Register';
import { mapActions, mapState } from "vuex";
import defaultImage from "../../assets/img/img-default.png";

export default {
  name: "ServiceBooking",
  data() {
    return {
      routeServices: "/services",
      routeBookingsAvailable: "/bookings/available-slots",
      routeBookings: "/bookings",
      routeStores: "/stores",
      slug: null,
      serviceSlug: null,
      service: {
        price: 0,
      },
      employees: [],
      store_uuid: null,
      store_name: null,
      bookingsAvailable: [],
      availableDays: [],
      avaibleDaysSlots:[],
      employeeSelected: "any",
      date: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      isModalShow: false,
      isLoginShow: true,
      isSavingBooking: false,
      isModalAddCalendar: false,
      isModalExtraService: false,
      selectedTime: null,
      attrs: [
        { 
          key: 'available',
          highlight: {
            color: "green",
            fillMode: "light",
          },
          dates: null,
        },
      ],
      locale: process.env.VUE_APP_I18N_LOCALE || "es",
      customer_comment: null,
      calendarData: {},
      isLoading: true,
      isLoadingMain: true,
      isLoadingBookingsAvailable: true,
      other_services: [],
      maxDate: null,
      isAfterTwoMonths: false,
      request: null
    };
  },
  computed: {
    longDate(){
      const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
      return new Date(this.date).toLocaleDateString(this.locale, dateOptions)
    },
    ...mapState(["isCustomer", "defaultTitle", "rebookEmployee"]),
  },
  methods: {
    async initData(){
      await this.getStore();
      await this.getService();
      if(this.rebookEmployee && this.employees.find( employee => employee.uuid === this.rebookEmployee)) {
        this.employeeSelected = this.rebookEmployee;
      }
      this.isLoadingMain = false;
      this.getBookingsAvailable();
    },
    async getStore() {
      const route = `${this.routeStores}/${this.slug}`;
      let response = await this.axios.get(route);

      if (response?.data.status === "success") {
        const { address, uuid, name, services } = response.data.data.stores;
        this.storeAddress = address;
        this.store_uuid = uuid;
        this.store_name = name;
        this.$store.dispatch("setStoreName", this.store_name);
        this.services = services;
      }
    },
    async getService() {
      const route = `${this.routeServices}/${this.slug}/${this.serviceSlug}`;
      let response = await this.axios.get(route);
      if (response?.data.status === "success") {
        const services = response.data.data.services;
        this.service = services;
        this.employees = services.employees;
      }
    },
    async getBookingsAvailable(startDate = this.formatDate(this.date)){
      // Cancels pending calls if you change employee or month while loading available slots.
      this.cancelRequest("newRequest");
      let axiosSource = this.axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      this.isLoadingBookingsAvailable = true;

      if(startDate > this.maxDate) {
        this.isLoadingBookingsAvailable = false;
        return;
      }

      try {
        const bookingData = {
          store_uuid: this.store_uuid,
          service_uuid: this.service.uuid,
          start_date: this.date,
          end_date: DateTime.fromJSDate( new Date( this.date ) ).endOf('month').toFormat('yyyy-MM-dd')
        };

        if(this.employeeSelected !== "any") bookingData.employee_uuid = this.employeeSelected;
 
        const response = await this.axios.post(this.routeBookingsAvailable, bookingData, { cancelToken: axiosSource.token });
  
        if (response?.data?.status === "success") {
          const { available_slots } = response.data.data;
          const newAvaibleDaysSlots = this.sortByParam(available_slots, 'date');

          // Avoids adding available slots to the wrong employees.
          const isOtherEmployee = bookingData?.employee_uuid !== this.employeeSelected;
          if(this.employeeSelected !== 'any' && isOtherEmployee) return;

          this.avaibleDaysSlots = [...this.avaibleDaysSlots, ...newAvaibleDaysSlots];
          this.availableDays = this.avaibleDaysSlots.map(day => new Date(day.date))
          this.attrs[0].dates = this.availableDays;

          if(this.avaibleDaysSlots.length > 0){
            const [ firstAvailableDay ] = this.availableDays;
            const [ firstAvailableSlot ] = this.avaibleDaysSlots;

            this.date = firstAvailableDay;
            this.bookingsAvailable = await this.sortByParam(firstAvailableSlot?.slots, 'hour');

            const [ firstBookingAvailable ] = this.bookingsAvailable;
            this.selectedTime = firstBookingAvailable?.hour;
          }
          this.isLoading = false;
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.request = null;
        this.isLoadingBookingsAvailable = false;
      }
    },
    controlServicesPictures(pictures) {
      let mainPicture = null;
      if (pictures?.length > 0) {
        pictures.forEach((picture) => {
          if (picture.type === "1") {
            mainPicture = picture.url;
          }
        });
      }
      return mainPicture;
    },
    goToStoreService(itemSlug) {
      this.$router.push({
        name: "storeService",
        params: { slug: itemSlug },
      });
    },
    setInitValues(){
      this.attrs[0].dates = null;
      this.avaibleDaysSlots = [];
      this.bookingsAvailable = [];
      this.availableDays = [];
      this.isLoading = true;
      this.selectedTime= null;
    },
    changeEmployee(employeeUuid) {
      if(this.isSavingBooking) return
      this.date = this.month === new Date().getMonth() +1? this.formatDate(new Date()): this.formatDate(this.date);
      this.employeeSelected = employeeUuid;
      this.setInitValues();
      this.getBookingsAvailable(this.date);
    },
    onChangeTab(tab) {
      if(! this.isSavingBooking){
        this.selectedTime =  DateTime.fromFormat(tab.label, "T").toFormat("TT");               
        return this.selectedTime;
      } 
    },
    async onDayClick(event) {
      if(this.date === null){
        this.date = event.date
        return
      }
      if(event.isDisabled || this.isSavingBooking) return;

      this.startDate = this.formatDate(event.id);
      this.bookingsAvailable = [];
      this.selectedTime = null;
      const newSlots = this.avaibleDaysSlots.find(day => day.date === event.id)?.slots;

      this.bookingsAvailable = await this.sortByParam(newSlots, 'hour');
      
      const [ firstBookingAvailable ] = this.bookingsAvailable;
      this.selectedTime = firstBookingAvailable?.hour;
    },
    toPageMoved(event) {
      let {month, year} = event;
      if( month === this.month && year === this.year) return

      this.year = year;
      this.month = month;
      const day = month === (new Date().getMonth() + 1) ? new Date().getDate() : 1;

      this.date = new Date(year, month - 1 , day);

      this.isAfterTwoMonths = DateTime.fromJSDate( new Date( this.date ) ).toFormat('yyyy-MM-dd') > this.maxDate

      if( this.isAfterTwoMonths ) {
        this.bookingsAvailable = [];
        return;
      }

      this.setInitValues();
      this.getBookingsAvailable();
    },
    editModalShow(isModalShow) {
      this.isModalShow = isModalShow;
    },
    async bookingService() {
      if (!localStorage.getItem("api_token")) {
        this.$store.dispatch("setPreviousPage", "/booking");
        this.isModalShow = true;
        return;
      }
      if (this.isCustomer) {
        if(this.isModalShow) this.isModalShow = false;
        this.isSavingBooking = true;
        await this.getStore();
        const route = `${this.routeBookings}`;
        const datetime = this.formatDate(this.date) + " " + this.selectedTime;

        // Select the first available employee
        let employeeUuid= this.employeeSelected;
        if(employeeUuid === 'any'){
          employeeUuid = this.bookingsAvailable.find( slot => slot.hour === this.selectedTime).employee[0];
        }
        
        const data = {
          store_uuid: this.store_uuid,
          service_uuid: this.service.uuid,
          employee_uuid: employeeUuid,
          datetime: datetime,
          customer_comment: this.customer_comment,
          other_services: this.other_services.map(item => item.uuid)
        };
        let response = await this.axios.post(route, data);
        if (response && response.data.status === "success") {
          this.isModalAddCalendar = true;
          this.calendarData = response.data.data.bookings;
          this.$toast.success(this.$t("bookingMessage.successfulBooking"));
        }
      } else {
        this.$toast.warning(this.$t("bookingMessage.onlyAvailableCustomer"));
      }
      this.rebookEmployee && this.$store.dispatch('setEmployeeToRebook', null);
      this.isSavingBooking = false;
    },
    addBookingToCalendar(isAdd) {
      if ( !isAdd ) {
        this.isModalAddCalendar = false;
        this.$router.push({ name: "myAppointments" });
        return;
      }
      
      const regex = /[-.:]/gm;
      const [ data ] = this.calendarData;
      let bookingData = {
        title: `${this.service.name} ${this.$t('at')} ${this.store_name}`, 
        start: DateTime.fromFormat( data.datetime,'yyyy-MM-dd HH:mm' ).toISO().replace(regex, ''),
        end: DateTime.fromFormat( data.datetime,'yyyy-MM-dd HH:mm' ).plus({ minutes: this.service.time }).toISO().replace(regex, ''),
        location: this.storeAddress,
        description: `${this.service.name} ${this.$t('at')} ${this.store_name}.%0A${this.$t('servedBy')}: ${data.employee.name}.%0A${this.$t('price')}: ${this.service.price} .%0A${this.$t('forMoreDetails')}:%0A${window.location.origin}/myAppointments`,
      };

      const URL = `https://www.google.com/calendar/render?action=TEMPLATE&text=${bookingData.title}&details=${bookingData.description}&location=${bookingData.location}&dates=${bookingData.start}%2F${bookingData.end}`;
      window.open(URL, '_blank');
    },
    showLogin(showLogin) {
      this.isLoginShow = showLogin;
    },
    sortByParam(array = [], param) {
      return array.sort((a, b) => b[param] < a[param] ? 1 : a[param] < b[param] ? -1 : 0);
    },
    formatDate(date){
      return DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd');
    },
    addAdditionalService(service){
      this.other_services.push(service);
      this.isModalExtraService = false;
    },
    removeExtraService(index){
      this.other_services.splice(index, 1);
    },
    cancelRequest(message) {
      if (this.request) this.request.cancel(message);
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
    ...mapActions(["setPreviousPage", "setStoreName"]),
  },
  beforeMount() {
    this.slug = this.$route.params.slug;
    this.serviceSlug = this.$route.params.serviceSlug;
    if (this.slug != null && this.serviceSlug != null) {
      this.initData()
    }
    this.$store.dispatch("setPreviousPage", this.$route.path);
    this.maxDate = DateTime.fromJSDate(new Date(this.date)).plus({ month: 2 }).toFormat('yyyy-MM-dd');
  },
  metaInfo() {
    if(this.store_name && this.service.name) {
      return {
        title: `${this.$t('book')} - ${this.service.name} - ${this.service.store.name} - ${this.service.store.locality} - ${this.$t('bookAppointmentsOnline')}`,
        meta: [
          {name: 'og:title', property: 'og:title', content: this.defaultTitle},
          {name: 'og:type', property: 'og:type', content: 'website'},
          {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
          {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.serviceBooking.description', [this.service.name, this.service.store.name, this.service.store.locality])},
          {name: 'description', content: this.$t('vueMeta.serviceBooking.description', [this.service.name, this.service.store.name, this.service.store.locality])},
          {name: 'og:image', property: 'og:image', content: this.service?.pictures[0]?.url || ''},
          {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
        ]
      }
    }
  },
  components: {
    BackButton,
    BaseTextarea,
    Card,
    DatePicker,
    Login,
    Modal,
    Register,
    Spinner,
    TabPane,
    TabsPills
  },
};
</script>

<style lang="scss" scoped>
.img-services,
.img-employee {
  width: 120px;
  min-height: 170px;
  max-height: 170px;
  object-fit: cover;
}
@media (min-width: 576px) {
  .img-services {
    height: 250px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.time-size {
  font-size: 15px;
}
.google-calendar-background {
  background-color: #344675;
}
.login-modal {
  z-index: 10;
  position: fixed;
}
.max-width-480 {
  max-width: 480px;
}
.card-body {
  display: grid;
  align-items: center;
}
.booking-card{
  min-height: 350px;
}
.dataPicker-height{
  min-height: 310px;
  width: 100%;
}
.img-employee2{
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.employee-btn{
  white-space: break-spaces;
  margin:5px;
  height: 60px;
  
  .btn-text{
    overflow: hidden;
    text-overflow: hidden;
    width: 70%;
    font-size: 14px;
  }
  .btn-img{
    height: 50px;
  }
}
.extra-service{
  color: black;
  &.body{
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .button{
    width: 100%;
  }
  .text-black-light{
    color: #86929d;
  }
}
.badge-service{
  background-color: #F2F2F2;
  width: max-content;
  padding: 8px;
  border-radius: 6.7px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover{
    cursor: pointer;
    .icon-remove{
      color:#FF4E50
    }
  }
}
.backButtonContainer {
  width: 100%;
  height: 80px;
}
@media (min-width: 576px) {
  .employee-btn{
    max-width: 12rem;
  }
}
@media (max-width: 768px) {
  .login-modal {
    height: 100vh;
    overflow: scroll;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .employee-btn{
    .btn-text{
      font-size: 10px;
    }
  }
}
</style>
