<template>
  <div class="d-flex justify-content-center align-items-center pt-5" :class="!modal? 'bg-white':''">
    <form @submit.prevent="login" @change="disabledBtn = false">
      <card class="card-login card-white text-left" :class="modal? 'p-5 shadow-lg' : ''">
        <div v-if="isLoginBooking">
          <div class="header-login-required">
            <em class="tim-icons icon-alert-circle-exc mr-2 excIcon"></em>
            <h4 class="login-required">{{ $t('loginRequired') }}</h4>
          </div>
          <hr>
        </div>
        <h4 class="card-title mb-4">{{ $t('login') }}</h4>

        <div>
          <base-input
              data-testid="input-email"
              name="email"
              inputType="email"
              v-model="form.email"
              :placeholder="$t('email')"
              addon-left-icon="tim-icons icon-email-85"
              required>
          </base-input>

          <base-input
              data-testid="input-password"
              name="password"
              inputType="password"
              v-model="form.password"
              :placeholder="$t('password')"
              addon-left-icon="tim-icons icon-lock-circle"
              required>
          </base-input>
        </div>

        <div slot="footer">
          <div class="pull-left">
            <h5>
              {{ $t("noAccountYet") }}
              <p class="link footer-link linkRegister" @click="isLoginBooking? $emit('showLogin', false): goToRegister()" data-testid="btn-register">
                {{ $t("signUp") }}
              </p>
            </h5>
            <h5>
              {{ $t("forgottenPassword") }}
              <p class="link footer-link linkRegister" @click="isRecoveryPassword = true" data-testid="btn-recovery">
                {{ $t("passwordRecovery") }}
              </p>
            </h5>
          </div>

          <base-button
              data-testid="btn-login"
              native-type="submit"
              type="success"
              class="mb-3"
              :disabled="disabledBtn"
              size="lg"
              block>
            {{ $t('login') }}
          </base-button>
          <base-button
              block
              type="danger"
              variant="danger"
              v-if="modal"
              size="lg"
              @click="$emit('changeModalShow', false)"
              >{{ $t("cancel") }}</base-button
            >
        </div>
      </card>
    </form>

    <modal :show="isRecoveryPassword" hide-footer id="recovery-modal">
        <header>
          <div class="row justify-content-between align-items-center px-4 mb-3">
            <h4 class="text-dark mb-0">{{ $t("passwordRecovery") }}</h4>
            <base-button simple class="px-3 py-2 col-1 d-flex justify-content-center" @click="closeModal()" data-testid="btn-closeModal">
              <em class="tim-icons icon-simple-remove text-dark"></em>
            </base-button>
          </div>
        </header>
        <div class="col-sm px-2 py-sm-4">
          <div v-if="isEmailSent">
            <h5 class="text-dark" data-testid="text-result-recovery-password">{{ $t(resultTextSendingEmail) }}</h5>
          </div>
          <div v-if="!isEmailSent && !sendingEmail">
            <form
              @change="disabledBtn = false"
              @submit.prevent="sendMailRecovery()"
              id="editCustomerForm"
            >
              <base-input
                inputType="email"
                v-model="emailToRecovery"
                :placeholder="$t('email')"
                :label="`${$t('email')}`"
                @change="canSendEmail = true"
                required
                class="pb-3"
                data-testid="input-email-recovery"
              ></base-input>
              <base-button
                native-type="submit"
                :disabled="disabledBtn"
                type="success"
                class="mb-3"
                size="lg"
                block
                data-testid="btn-recovery-password"
                >
                {{ $t('recovery') }}
              </base-button>
            </form>
          </div>
          <div v-if="sendingEmail" class="py-3">
            <Spinner classes="mx-auto"></Spinner>
          </div>
        </div>
    </modal>
  </div>
</template>
<script>
import { Card, BaseInput, Modal, Spinner, BaseButton } from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
   props: {
    modal: {
      type: Boolean,
      default: false,
    },
    isLoginBooking: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      form: {},
      isRecoveryPassword: false,
      isEmailSent: false,
      emailToRecovery: null,
      sendingEmail: false,
      disabledBtn: true,
      resultTextSendingEmail: null,
    };
  },
  computed: {
    ...mapState(["isCustomer", "isAdmin", "isManager", "previousPage", "defaultTitle"]),
  },
  methods: {
    async login() {
      this.disabledBtn = true;
      const route = "/auth/login";
      const meRoute = "/me";

      let response = await this.axios.post(route, this.form);
      if (response && response.data.status === "success") {
        const user = response.data.data.user;
        const jwt = response.data.data.jwt;
        const expirationDate = new Date(
          new Date().getTime() + jwt.expires_in * 1000
        );

        localStorage.setItem("userRole", user.role);
        this.$store.dispatch("setRole", user.role);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("api_token", jwt.token);
        localStorage.setItem("token_expires_in", expirationDate);
        if (user.role ==="Employee" ||user.role === "Manager") {
          localStorage.setItem("store", user.store.name);
        }
        
        if (typeof WebviewEventsChannel !== 'undefined' ) {
          const userData = {
            jwtToken: jwt.token
          };
          WebviewEventsChannel.postMessage(JSON.stringify(userData));
        } else {
          console.debug('not running inside a Flutter webview');
        }

        this.axios.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem("api_token")}`,
        };

        let meResponse = await this.axios.get(meRoute);
        if (meResponse && meResponse.data.status === "success") {
          localStorage.setItem( "profilePicture", meResponse.data.data.user.picture );
        }
        
        this.$store.dispatch( "setAuthenticate", localStorage.getItem("api_token") );
        this.$toast.success(this.$t("welcome"));

        let pageTo = this.previousPage ? this.previousPage : "/";
        if (this.isCustomer) {
            if (pageTo !== "/booking") {
              this.$router.push({ path: pageTo });
            } else {
              this.$emit('saveBooking');
            }          
        } else if (this.isAdmin) {
          this.$router.push({ name: "userList" });
        } else if (this.isManager) {
          this.$router.push({ name: "bookings" });
        } else {
          this.$router.push({ name: "bookings" });
        }
      }
      this.disabledBtn = false;
    },
    async sendMailRecovery() {
      this.sendingEmail = true;
      const route = `auth/forgot-password?email=${this.emailToRecovery}`;
      const response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.resultTextSendingEmail = "recoveryEmailSent";
        this.isEmailSent = true;
        this.sendingEmail = false;
      } else {
        this.resultTextSendingEmail = "recoveryEmailNoSent";
      }
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    closeModal() {
      this.resultTextSendingEmail = null;
      this.isRecoveryPassword = false;
      this.emailToRecovery = null;
      this.isEmailSent = false;
    },
    ...mapActions(["setRole", "setPreviousPage", "setAuthenticate"]),
  },
  metaInfo() {
    if(this.defaultTitle) {
      return { title: `${this.$t('login')} - ${this.defaultTitle}` }
    }
  },
  components: {
    Card,
    BaseInput,
    Modal,
    Spinner,
    BaseButton
  },
};
</script>
<style scoped>
input:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}
.login-required {
  color: red;
  font-weight: 500;
  font-size: 14px;
}
.header-login-required {
  display: flex;
}
.excIcon {
  color:red;
  font-size: 20px;
}
.linkRegister {
  display: inline-block;
  cursor: pointer;
  color: #E87943;
}
</style>
